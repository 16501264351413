import { connect } from 'react-redux'
import { TravelThunkDispatch, getTerminals, fetchTerminals, fetchCorp, getCorp } from 'store/travel'
import { ApplicationState } from 'store'
import TerminalsLayout from './TerminalsLayout'

export const mapState = (state: ApplicationState) => {
  return {
    terminals: getTerminals(state),
    corp: getCorp(state)
  }
}

type ParcelDispatch = TravelThunkDispatch

export const mapDispatch = (dispatch: ParcelDispatch) => ({
  getTerminals: () => dispatch(fetchTerminals()),
  getCorp: () => dispatch(fetchCorp())
})

export default connect(mapState, mapDispatch)(TerminalsLayout)
