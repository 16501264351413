import Config from 'core/config/Config'
import { ApiService } from 'core/services'
import { Schedules } from 'modules/terminals/models/schedules'

class TerminalsService {
  public static instance: TerminalsService

  constructor() {
    if (TerminalsService.instance) {
      return TerminalsService.instance
    }
    TerminalsService.instance = this
  }

  getScheduleTerminal() {
    return ApiService.get<Schedules[]>('branchoffices/schedules/public/web', { url: Config.apiUrl })
  }
}

const instance = new TerminalsService()

Object.freeze(instance)

export const TerminalService = instance
